.rockcard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rockcard-image {
    height: 200px;
    cursor: pointer;
}

.rockcard-text {
    text-align: center;
    padding: 10px;
}
.rockcard-text_name {
    margin: 0px;
}
.rockcard-text_desc {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.rockcard-text_desc p {
    /* width: 50%; */
}
