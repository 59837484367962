.content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: var(--background-primary);
    /* background-color: #ffffff; */
    width: 50%;
    min-height: 70vh;
    margin: 5vh 5vw;
    padding: 1vh 2vw;
    border-radius: 25px;
    box-shadow: -15px -10px 5px 0px var(--blue-primary), 15px 10px 5px 0px var(--pink-primary);
}

.content-markiplier {
    position: fixed;
    top: var(--header-height);
    left: 0;
}

.content-jerma {
    position: fixed;
    top: var(--header-height);
    right: 0;
}

@media screen and (max-width: 1000px) {
    .content {
        width: 80%;
        /* border-radius: 0; */
        /* margin: 2vh 5vw; */
    }

    .content-markiplier {
        width: 200px;
    }

    .content-jerma {
        width: 200px;
    }
}

@media screen and (max-width: 700px) {
    .content {
        width: 80%;
        /* border-radius: 0; */
        /* margin: 2vh 5vw; */
        box-shadow: none;
    }

    .content-markiplier {
        width: 150px;
    }

    .content-jerma {
        width: 150px;
    }
}
