.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--font-color);
  background: conic-gradient(from 0deg at 50% 45vw,
    rgba(255, 52, 52, 1)  0%,
    rgba(0, 0, 0, 1)      15%,
    rgba(0, 0, 0, 1)      19%,
    var(--blue-primary)     20%,
    var(--blue-secondary)   25%,
    rgba(0, 0, 0, 1)      30%,
    rgba(0, 0, 0, 1)      70%,
    var(--pink-secondary)   75%,
    var(--pink-primary)     80%,
    rgba(0, 0, 0, 1)      81%,
    rgba(0, 0, 0, 1)      85%,
    rgba(255, 52, 55, 1)  100%);
}

a {
  color: unset;
  text-decoration: none;
}
a:hover {
  color: var(--blue-primary);
}

.global-buttonlink {
  margin: 0 10px;
  padding: 10px 30px;
  border-radius: 10px;
  color: black;
  background-color: var(--blue-primary);
  cursor: pointer;
  outline: none;
  border: none;
  display: inline;
  white-space: nowrap;
}
.global-buttonlink:hover {
  background-color: var(--blue-secondary);
}

@media screen and (max-width: 1000px) {
  .App {
    background: conic-gradient(from 0deg at 50% 65vw,
      rgba(255, 52, 52, 1)  0%,
      rgba(0, 0, 0, 1)      15%,
      rgba(0, 0, 0, 1)      19%,
      var(--blue-primary)     20%,
      var(--blue-secondary)   25%,
      rgba(0, 0, 0, 1)      30%,
      rgba(0, 0, 0, 1)      70%,
      var(--pink-secondary)   75%,
      var(--pink-primary)     80%,
      rgba(0, 0, 0, 1)      81%,
      rgba(0, 0, 0, 1)      85%,
      rgba(255, 52, 55, 1)  100%);
  }
}

@media screen and (max-width: 700px) {
  .App {
    background: conic-gradient(from 0deg at 50% 100vw,
      rgba(255, 52, 52, 1)  0%,
      rgba(0, 0, 0, 1)      10%,
      rgba(0, 0, 0, 1)      14%,
      var(--blue-primary)     15%,
      var(--blue-secondary)   30%,
      rgba(0, 0, 0, 1)      40%,
      rgba(0, 0, 0, 1)      60%,
      var(--pink-secondary)   70%,
      var(--pink-primary)     85%,
      rgba(0, 0, 0, 1)      86%,
      rgba(0, 0, 0, 1)      90%,
      rgba(255, 52, 55, 1)  100%);
  }
}
