.secretmenu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(50, 50, 50, 0.80);

    display: flex;
    justify-content: center;
    align-items: center;
}

.secretmenu-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--background-primary);

    min-height: 50vh;
    width: 40%;

    padding: 1vh 2vw;
    border-radius: 25px;
    box-shadow: -15px -10px 5px 0px var(--pink-primary), 15px 10px 5px 0px var(--blue-primary);
}
