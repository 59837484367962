.rocks {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    /* background-color: #ffffff; */
}

.rocks-grid {
    display: grid;
    grid-template-columns: repeat(2, 50%);
}

@media screen and (max-width: 700px) {
    .rocks-grid {
        grid-template-columns: repeat(1, 100%);
    }
}
