.home {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    /* background-color: #ffffff; */
}

.home__title {
    margin-bottom: 10px;
}

.home__subtitle {
    margin: 0;
}

.home__links {
    flex-direction: row;
    margin: 2rem 0;
}

.home__langs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.home__langs img {
    height: 100px;
    width: 100px;
}

.home__certifications {
    flex-direction: row;
    align-items: center;
}