.phonetics {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.phonetics__props {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
    margin-bottom: 20px;
    font-weight: bold;
}
.phonetics__props input {
    width: 50px;
}

.phonetics__props-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.phonetics__props-item_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}

.phonetics__props-item_info {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.phonetics__props-item span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.phonetics button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 200px;
    background-color: var(--blue-primary);
    border: none;
    border-radius: 10px;
}

.phonetics__loading {
    display: flex;
    align-items: center;
    margin-top: 100px;
}
