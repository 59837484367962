.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    background-color: var(--background-primary);
    height: var(--header-height);
    width: 100vw;

    z-index: 500;
}

.header-secret {
    width: 5rem;
    height: var(--header-height);

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.5rem;
}

.header-secret button {
    width: 75%;
    height: 50%;
}
