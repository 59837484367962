.astrocard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.astrocard-image {
    /* height: 200px; */
    width: 75%;
    cursor: pointer;
}

.astrocard-text {
    text-align: center;
    padding: 10px;
}
.astrocard-text_name {
    margin: 0px;
}
.astrocard-text_desc {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.astrocard-text_desc p {
    /* width: 50%; */
}
