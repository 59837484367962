#fullSizeImgView {
    margin: 0;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(50, 50, 50, 0.80);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

#fullSizeImgView img {
    height: 95vh;
    width: auto;
}

@media screen and (max-width: 700px) {
    #fullSizeImgView img {
        height: auto;
        width: 95vw;
    }
}