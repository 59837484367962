body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  /* height: fit-content;
  width: fit-content; */
  padding: 10px 10px;
  /* background-color: var(--blue-secondary);
  box-shadow: -5px -5px 3px 0px var(--blue-primary), 5px 5px 3px 0px var(--pink-primary); */

  font-weight: bold;
  font-size: 1.2em;
}

:root {
  --font-color: white;
  --background-primary: #060034;

  --pink-primary: rgba(255, 131, 255, 1);
  --pink-secondary: rgba(113, 0, 113, 1);
  --blue-primary: rgba(0, 188, 212, 1);
  --blue-secondary: rgba(0, 0, 113, 1);

  --header-height: 5rem;
}
