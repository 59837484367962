.navbar {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.navbar__links {
    height: 100%;
}
.navbar__links-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
}
.navbar__links-container p {
    height: 100%;
    margin: 0rem 1rem;
}
.navbar__links-container p a {
    cursor: pointer;

    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar__menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}
.navbar__menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--background-primary);
    position: absolute;
    right: 0;
    top: 40px;
    min-width: 110px;
    border-radius: 5px;
    box-shadow: -5px 5px 10px 5px var(--blue-primary);
}
.navbar__menu-container p {
    width: 100%;
    margin: 0.5rem;
}
.navbar__menu-container p a {
    cursor: pointer;

    height: 100%;
    padding: 0.5rem 1.5rem;
    display: block;
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem;
    }

    .navbar__links-container {
        display: none;
    }

    .navbar__menu {
        display: flex;
    }
}

.navbar__menu-container--close {
    max-height: 0;
    padding: 0;
    box-shadow: none;
    overflow: hidden;
	transition: max-height,padding 0.2s ease-out;
}
.navbar__menu-container--open {
    max-height: 1000px;
	transition: max-height,padding 0.2s ease-in;
}
